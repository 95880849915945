import React, { ReactNode, createContext, useContext } from "react";

export type CountryContextType = {
  country: string;
};

const CountryContext = createContext<CountryContextType | undefined>(undefined);

export const CountryProvider: React.FC<{
  children: ReactNode;
  country: CountryContextType["country"];
}> = ({ children, country }) => {
  return (
    <CountryContext.Provider value={{ country }}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => {
  const context = useContext(CountryContext);
  if (!context) {
    throw new Error("useCountry must be used within a CountryProvider");
  }
  return context;
};
